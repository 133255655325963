
import { Options, Vue } from 'vue-class-component'
import theClubList from '@/components/Frontend/ClubList/index.vue'

@Options({
  components: {
    theClubList
  }
})
export default class Clubs extends Vue {}
