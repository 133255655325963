import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import BaseFunctions, { defaultMissingImgURL, LogoObj, mediaBaseURL } from '@/components/Utility/Base'
import CommonFunctions, { defaultImageURL } from '@/components/Utility/Common'
import ClubsDataService from '@/services/ClubsDataService'
import MembersDataService from '@/services/MembersDataService'
import TeamsDataService from '@/services/TeamsDataService'
import RegionsDataService from '@/services/RegionsDataService'
import ByPostDataService from '@/services/ByPostDataService'
import SeasonsDataService from '@/services/SeasonsDataService'
import ExternalServicesDataService from '@/services/ExternalServicesDataService'
import { Tournaments } from '@/services/TournamentsDataService'
import OpeningHoursDataService from '@/services/OpeningHoursDataService'
import MediasDataService from '@/services/MediasDataService'
import SysTournamentRow, { SysTournamentCategoryId } from '@/types//SysTournament'
import SysClub, { clubOpeningDaysFriendlyType, WheelchairFriendlyType } from '@/types/SysClub'
import SysTeam from '@/types/SysTeam'
import SysMember from '@/types/SysMember'
import SysRegion from '@/types/SysRegion'
import SysSaeson from '@/types/SysSaeson'
import ByPost from '@/types/ByPost'
import SysExternalServices from '@/types/SysExternalServices'
import SysOpeningHours, { openingDaysFriendlyType } from '@/types/SysOpeningHours'
import { Loader } from '@googlemaps/js-api-loader'
import exportFromJSON from 'export-from-json'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import UsersDataService from '@/services/UsersDataService'
import SysUser from '@/types/SysUser'

/* export default defineComponent({
  name: 'AdminUsers',
  setup () {
    // component setup
  }
}) */

type klubDataType = { klubStatus: boolean; klubLogo: string; klubKlubnavn: string; klubVejnavn: string; klubPostnummer: string; klubBy: string; klubRegion: string; klubTelefonnummer: string; klubEmail: string, klubBeskrivelse: string, klubAAben: string; klubSaldo: string; klubHjemmekampe: string; klubBaner: string; klubEtableringsaar: string; klubKoerestolsvenlig: boolean; klubFacebook: string; klubHjemmeside: string; klubSlug: string; klubAabningstider: any; klubLicens: string; }
type OpeningHoursDataType = { open: string; close: string; day: string; clubId: number; comment: string; }
type memberDataType = { brugerFornavn: string; brugerEfternavn: string; brugerTelefon: string; brugerEmail: string; medlemFoedselsdag: string; medlemVejnummer: string; medlemPostnummer: number; medlemBy: string; paradartspillerCheck: boolean; medlemKoen: string; medlemStatus: boolean; medlemLicens: number; medlemBegyndelse: string; medlemKarantaeneDato: string; medlemKarantaeneSlutdato: string; medlemKlubRolle: string; }
type dataReturnType = { klubbers: any; addManagerToClubValue: any; membersForNewClub: any; openingHours: any; exportClubs: any; ageRange: number[]; imageUpload: any; selectedImage: any; totalPages: number; tabValue: string; index: number; quillToolbar: any; error: any; }

@Options({
  props: {
    msg: String
  },
  components: {
    QuillEditor
  }
})
export default class AdminClubs extends Vue {
  msg!: string
  private todaysDate = (new Date()).toISOString().split('T')[0]
  private customLicenseEndDate = false
  private newLicenseEndDate = this.todaysDate
  // private nowDate = Date.now()
  private tabValue = 'Vis og rediger klubber'
  private klubbers: SysClub[] = []
  private openingHours: SysOpeningHours[] = []
  private tempHolds: SysTeam[] = []
  private tempKlub = {} as SysClub
  private tempHold = {} as SysTeam
  private tempMedlem = {} as SysMember
  private tempMedlems: SysMember[] = []
  private tempMembers: SysMember[] = []
  private membersForNewClub: SysMember[] = []
  private tempTournamentCategories: SysTournamentCategoryId[] = []
  private tempSeasons: SysSaeson[] = []
  private tempTournamentRows: SysTournamentRow[] = []
  private bypost: ByPost[] = []
  private regions: SysRegion[] = []
  private externalservices = {} as SysExternalServices
  private exportClubs: any[] = []
  klubData: klubDataType = { klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubFacebook: '', klubHjemmeside: '', klubSlug: '', klubAabningstider: [], klubLicens: '' }
  openingData: OpeningHoursDataType = { open: '', close: '', day: '', clubId: 0, comment: '' }
  memberData: memberDataType = { brugerFornavn: '', brugerEfternavn: '', brugerTelefon: '', brugerEmail: '', medlemFoedselsdag: '', medlemVejnummer: '', medlemPostnummer: 0, medlemBy: '', paradartspillerCheck: false, medlemKoen: '', medlemStatus: true, medlemLicens: 0, medlemBegyndelse: '', medlemKarantaeneDato: '1970-01-01', medlemKarantaeneSlutdato: '1970-01-01', medlemKlubRolle: '' }
  error: any = null
  filterStatusStringValue = 'Vis alt'
  filterStatusStringOptions: string[] = ['Vis alt', 'Aktiv licens', 'Ikke aktiv']
  klubRegionFilterValue = { name: 'Vis alt', id: '0' }
  klubRegionFilterOptions: { name: string, id: string }[] = [{ name: 'Vis alt', id: '0' }]
  klubRegionTrackByValue = { name: 'Vælg her', id: '0' }
  klubRegionTrackByOptions: { name: string, id: string }[] = [{ name: 'Vælg her', id: '0' }]
  klubTeamAddAmountValue: { name: string, id: string }[] = []
  klubTeamAddAmountOptions: { name: string, id: string }[] = [{ name: 'Vælg her', id: '0' }, { name: '1 hold', id: '1' }, { name: '2 hold', id: '2' }, { name: '3 hold', id: '3' }, { name: '4 hold', id: '4' }, { name: '5 hold', id: '5' }, { name: '6 hold', id: '6' }, { name: '7 hold', id: '7' }, { name: '8 hold', id: '8' }, { name: '9 hold', id: '9' }, { name: '10 hold', id: '10' }]
  klubTeamMemberNameValue: { name: string, id: string }[] = []
  klubTeamMemberNameOptions: { name: string, id: string }[] = []
  klubTeamMemberShowSelectionForThisCategory: boolean[] = []
  addClubMembersDrawerVisible = false
  addClubTeamsDrawerVisible = false
  addOpeningHoursDrawerVisible = false
  editOpeningHoursDrawerVisible = false
  showClubTeamMemberSelection = false
  deleteClubWarningModal = false
  exportModal = false
  memberSearchInputValue = ''
  memberSearchTerm = ''
  clubSearchInputValue = ''
  clubSearchTerm = ''
  toggleIconsSortingValue = 0
  private oldSort = ''
  private oldClubName = ''
  lastCreatedClubId = 0
  lastCreatedClubTeamId = 0
  private submitted = false
  private editClubModal = false
  private editClubSaldoModal = false
  private extraMemberModal = false
  private deleteOpeningHoursWarningModal = false
  private deleteOpeningHourId = 0
  private lastEditedKlubId = 0
  private lastEditMembersOfKlubId = 0
  private lastEditTeamsOfKlubId = 0
  private lastEditMembersOfKlubManagerMemberId = 0
  private lastEditClubId = 0
  private lastEditClubName = ''
  private lastEditMembersOfKlubName = ''
  private lastEditMembersOfKlubLogo: any = null
  private currentTournamentSeasonId = 0
  private currentTournamentSeasonStartDate = '1970-01-01'
  private currentListSortingOrder = ''
  private currentByPostId = 0
  private inhibitUpdateCityInfo = false
  private deleteClubId = 0
  private deleteClubName = ''
  // private useProfileAvatar = false
  private medlemByPostnummerInputFocus = 0 // Used to track which watch-tied input fields has the current input focus. Value 1 = city input field is in focus, value 2 = postal code input field is in focus
  private index = 0
  private currentPage = 1
  private totalPages = 0
  private totalNumberOfPages = 0
  private currentMembersSorting = ''
  private extraMemberParameter = ''
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  private addManagerToClubValue = { id: 0, name: 'Vælg klubansvarlig' }
  private addManagerToClubOptions: {id: number, name: string }[] = [{ id: 0, name: 'Vælg klubansvarlig' }]
  private memberSearchInputString = ''
  private keyPress = ''
  private backSpace = ''
  openingHoursDayValue = ''
  openingHoursDayOptions = Object.values(openingDaysFriendlyType).filter(value => typeof value === 'string').filter(value => !value.toString().includes('oe'))
  openingHoursArray: { day: string; open: string; close: string; comment: string }[] = []
  private columnName: string[] = ['name', 'city', 'telephone', 'balance', 'members', 'teams']
  private extraParameter = ''
  membershipCount: {clubId: string, count: number}[] = []
  loadingDataFlag = false
  private ageRange: number[] = [0, 100]
  private order = 0
  private exportFieldsFilterValue: { name: string, value: string, id: string}[] = []
  private exportFieldsFilterOptions: { name: string, value: string, id: string }[] = [
    { name: 'Klubnavn', value: 'klubber_klubnavn', id: 'id' },
    { name: 'Email', value: 'klubber_email', id: 'id' },
    { name: 'Telefon', value: 'klubber_telefonnummer', id: 'id' },
    { name: 'Region', value: 'region_id.region_navn', id: 'id' },
    { name: 'Adresse', value: 'klubber_vejnavn', id: 'id' },
    { name: 'By', value: 'bypost_id.bypost_by', id: 'id' },
    { name: 'Postnummer', value: 'bypost_id.bypost_postnummer', id: 'id' },
    { name: 'Saldo', value: 'klubber_saldo', id: 'id' },
    { name: 'Baner', value: 'klubber_baner', id: 'id' },
    { name: 'Hjemmekampe', value: 'klubber_hjemmkampe', id: 'id' },
    { name: 'Etableret', value: 'klubber_etableringsaar', id: 'id' },
    { name: 'Hjemmeside', value: 'klubber_hjemmeside', id: 'id' },
    { name: 'Facebook', value: 'klubber_facebook', id: 'id' },
    { name: `Medlemmer mellem ${this.ageRange[0]} og ${this.ageRange[1]}`, value: 'count', id: 'id' }
  ]

  private exportDropDown = false
  exportRegionFilterValue = { name: 'Alle regioner', id: 0 }
  exportRegionFilterOptions: { name: string, id: number }[] = [{ name: 'Alle regioner', id: 0 }]
  // Global variables for image handeling
  private imageFiles: any[] = []
  private displayImagesModal = false
  private selectedImage = <any>[]
  private totalNumberOfImages = 0
  private totalNumberOfImagePages = 0
  private imageSearchTerm = ''
  private imageSearchInputValue = ''
  private currentImagePage = 1
  // private imageUpload: any[] = []
  private imageUpload: any = []
  toolbarOptions: any[] = [
    ['bold', 'italic', 'underline'],
    [],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [],
    [{ align: [] }]
  ]

  readonly name : string = 'AdminUsers'
  readonly defaultClubDescription : string = 'Informationer om klubben er på vej ...'
  $Message: any

  data (): dataReturnType {
    return {
      klubbers: this.klubbers,
      membersForNewClub: this.membersForNewClub,
      addManagerToClubValue: this.addManagerToClubValue,
      openingHours: this.openingHours,
      exportClubs: this.exportClubs,
      ageRange: [0, 100],
      imageUpload: this.imageUpload,
      selectedImage: this.selectedImage,
      totalPages: this.totalPages,
      tabValue: 'Vis og rediger klubber',
      quillToolbar: {
        modules: {
          toolbar: {
            container: this.toolbarOptions
          }
        }
      },
      index: this.index,
      error: this.error
    }
  }

  declare $refs: {
    clubDescription: typeof QuillEditor
  }

  @Watch('ageRange')
  onAgeChange () : void {
    this.exportFieldsFilterOptions[13] = { name: `Medlemmer mellem ${this.ageRange[0]} og ${this.ageRange[1]}`, value: 'count', id: 'id' }
  }

  public async giveManagerRights () : Promise<void> {
    let clubs: SysClub[] = []
    try {
      clubs = (await ClubsDataService.getAll('', null, '')).data
    } catch (err) {
      console.log(err)
    }

    for (const club of clubs) {
      console.log('[giveManagerRights()] club = ' + JSON.stringify(club))
      if (club.klubber_ansvarlig_id !== undefined && club.klubber_ansvarlig_id !== null && !(JSON.stringify(club.klubber_ansvarlig_id) === '{}')) {
        console.log('[giveManagerRights()] klubber_ansvarlig_id = ' + JSON.stringify(club.klubber_ansvarlig_id))

        MembersDataService.get(Number(club.klubber_ansvarlig_id.id).toString())
          .then((response) => {
            const member = response.data as SysMember
            console.log('[giveManagerRights()] 2')
            // Update only relevant users - hands off and do not tamper with the special users with Id values lower than 100!
            if (member.user_id !== undefined && member.user_id.id !== undefined && Number(member.user_id.id) >= 100 && member.user_id.usrgroup !== null && member.user_id.usrgroup !== 3 && member.user_id.usrgroup !== 2) {
              const updateUser = {
                usrgroup: 3,
                role: '3'
              }
              console.log('[giveManagerRights()] 3 : ' + Number(member.user_id.id).toString())
              UsersDataService.update(Number(member.user_id.id).toString(), updateUser)
                .then((response) => {
                  const user = response.data as SysUser
                  console.log(user.username + ' updated: ' + response.statusText)
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  @Watch('exportDropDown')
  onExportDropDownChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.exportRegionFilterValue = { name: 'Alle regioner', id: 0 }
      this.exportFieldsFilterValue = []
    }
  }

  @Watch('exportFieldFilterValue')
  onExportFilterValueChange () : void {
    console.log(this.exportFieldsFilterValue)
  }

  @Watch('klubRegionFilterValue')
  onklubRegionFilterValueChange () : void {
    if (this.klubRegionFilterValue.id !== '0') {
      this.extraParameter = 'region_id.id=' + this.klubRegionFilterValue.id
    } else {
      this.extraParameter = ''
    }
    this.retrieveClubs()
    CommonFunctions.scrollToTop()
  }

  @Watch('backSpace', { deep: true })
  onBackSpacePress () : void {
    if (this.backSpace === 'Backspace' && this.memberSearchInputString.length !== 0) {
      this.memberSearchInputString = this.memberSearchInputString.substring(0, this.memberSearchInputString.length - 1)
      this.backSpace = ''
      // console.log(this.memberSearchInputString)
    }
  }

  @Watch('memberSearchInputString', { deep: true })
  watchInputString () : void {
    console.log(this.memberSearchInputString)
    if (this.memberSearchInputString.length > 3) {
      this.memberSearchTerm = this.memberSearchInputString
      this.addNewMembers()
    }
  }

  @Watch('deleteClubWarningModal')
  onDeleteClubWarningModalChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteClubId = 0
      this.deleteClubName = ''
    }
  }

  @Watch('editOpeningHoursDrawerVisible')
  onOpeningHoursDrawerToggleChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.openingData = { open: '', close: '', day: '', clubId: 0, comment: '' }
      this.openingHoursDayValue = ''
    }
  }

  @Watch('addClubMembersDrawerVisible')
  onDrawerVisibleToggleChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.memberSearchInputValue = ''
      this.memberSearchTerm = ''
    }
  }

  @Watch('filterStatusStringValue')
  onClubStatusChanged (statusValue: string, oldStatusValue: string) : void {
    console.log('Filter status changed to: ' + statusValue)
    this.retrieveClubs()
    CommonFunctions.scrollToTop()
  }

  @Watch('extraMemberModal')
  onExtraMemberModalToggleChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.memberData = { brugerFornavn: '', brugerEfternavn: '', brugerTelefon: '', brugerEmail: '', medlemFoedselsdag: '', medlemVejnummer: '', medlemPostnummer: 0, medlemBy: '', paradartspillerCheck: false, medlemKoen: '', medlemStatus: true, medlemLicens: 0, medlemBegyndelse: '', medlemKarantaeneDato: '1970-01-01', medlemKarantaeneSlutdato: '1970-01-01', medlemKlubRolle: '' }
      this.editMembersClub(this.lastEditMembersOfKlubId)
    }
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrieveClubs()
    CommonFunctions.scrollToTop()
  }

  @Watch('editClubModal')
  onEditClubModalToggleChanged (toggleValue: boolean, oldToggleValue: boolean) : void {
    if (toggleValue === false) {
      this.klubData = { klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubFacebook: '', klubHjemmeside: '', klubSlug: '', klubAabningstider: [], klubLicens: '' }
      this.klubRegionTrackByValue = { name: 'Vælg her', id: '0' }
      this.oldClubName = ''
      this.memberSearchInputString = ''
    }
  }

  @Watch('editClubSaldoModal')
  onClubSaldoModalChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.klubData = { klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubFacebook: '', klubHjemmeside: '', klubSlug: '', klubAabningstider: [], klubLicens: '' }
    }
  }

  @Watch('klubData.klubBy')
  onMedlemByFieldChanged (fieldByValue: string, oldFieldByValue: string) : void {
    console.log('Field value changed to: ' + fieldByValue)

    if (this.medlemByPostnummerInputFocus === 1 && fieldByValue !== null) {
      this.updatePostalCodeInputFieldDataFromCityInputFieldData(fieldByValue)
    }
  }

  @Watch('klubData.klubPostnummer')
  onMedlemPostnummerFieldChanged (fieldPostnummerValue: string, oldFieldPostnummerValue: string) : void {
    console.log('Field value changed to: ' + fieldPostnummerValue)

    if (this.medlemByPostnummerInputFocus === 2 && this.inhibitUpdateCityInfo === false && !isNaN(Number(fieldPostnummerValue))) {
      this.updateCityInputFieldDataFromPostalCodeInputFieldData(fieldPostnummerValue)
    }
  }

  @Watch('deleteOpeningHoursWarningModal')
  onDeleteOpeningHoursWarningToggleChange (toggleValue: boolean) : void {
    if (toggleValue === false) {
      this.deleteOpeningHourId = 0
    }
  }

  @Watch('medlemByPostnummerInputFocus')
  onMedlemByPostnummerInputFocusChanged (fieldInputFocusValue: string, oldInputFocusValue: string) : void {
    console.log('Input field focus value changed to: ' + fieldInputFocusValue)

    if (Number(fieldInputFocusValue) === 1) {
      if (!isNaN(Number(this.klubData.klubPostnummer))) {
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.klubData.klubPostnummer)
      }
    } else if (Number(fieldInputFocusValue) === 2) {
      if (this.klubData.klubBy !== null) {
        this.updatePostalCodeInputFieldDataFromCityInputFieldData(this.klubData.klubBy)
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.klubData.klubPostnummer)
      }
    }
  }

  public disableLicenseRenewalBtn (date: string) : boolean {
    const renewalOpen = new Date(new Date().getFullYear() + '-12-01').toISOString()
    if (date === '1970-01-01' || Date.parse(this.todaysDate) > Date.parse(date) || Date.parse(this.todaysDate) >= Date.parse(renewalOpen) || this.customLicenseEndDate) {
      return false
    }
    return true
  }

  public renewLicense (currentLicenseEndDate: string) : void {
    const currentYear: number = new Date().getFullYear()
    let licenseEndDate = ''
    if (this.customLicenseEndDate) {
      licenseEndDate = this.newLicenseEndDate
    } else if (new Date(currentLicenseEndDate) < new Date(currentYear.toString() + '-12-01') && new Date(this.todaysDate) < new Date(currentYear.toString() + '12-01')) {
      licenseEndDate = currentYear.toString() + '-12-31'
    } else {
      licenseEndDate = (currentYear + 1).toString() + '-12-31'
    }

    const updateClub = {
      klubber_kontingent_slut: licenseEndDate
    }

    ClubsDataService.update(this.lastEditedKlubId.toString(), updateClub)
      .then((response) => {
        console.log(response.data)
        this.customLicenseEndDate = false
        this.newLicenseEndDate = this.todaysDate
        this.editClub(this.lastEditedKlubId)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public activeLicense (date: string) : boolean {
    if (this.todaysDate > date || date === null) {
      return false
    }

    return true
  }

  public toDanishDateString (dateString: string, dateResultVariant = 0) : string {
    return CommonFunctions.toDanishDateString(dateString, dateResultVariant)
    // return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/')
  }

  public showColumn (column: string) : boolean {
    return this.columnName.includes(column)
  }

  public deleteOpeningHourWarning (id: number) : void {
    this.deleteOpeningHourId = id
    this.deleteOpeningHoursWarningModal = true
  }

  public removeOpeningHours (numberInArray: number) : void {
    this.openingHoursArray.splice(numberInArray, 1)
  }

  public addToOpeningHoursArray () : void {
    if (this.openingHoursDayValue !== '' && this.openingData.open.length > 0 && this.openingData.close.length > 0 && this.openingData.open < this.openingData.close) {
      this.openingHoursArray.push({ day: this.openingHoursDayValue, open: this.openingData.open, close: this.openingData.close, comment: (this.openingData.comment !== '' ? this.openingData.comment : '') })
      console.log('openHoursArray' + JSON.stringify(this.openingHoursArray))

      this.openingData = { open: '', close: '', day: '', clubId: 0, comment: '' }
      this.openingHoursDayValue = ''
    }
  }

  public parseEnum (typeString: any) : openingDaysFriendlyType {
    return <any>openingDaysFriendlyType[typeString]
  }

  public clearInput () : void {
    console.log('Trigger event')
    this.memberSearchInputString = ''
    console.log(this.addManagerToClubValue)
  }

  public addNewMembers () : void {
    // this.addManagerToClubOptions = []
    console.log('Fetching Members and adding them to array')
    MembersDataService.findByNameAndLicenseSearchTerm(this.memberSearchTerm)
      .then((response) => {
        this.membersForNewClub = response.data
        this.addManagerToClubOptions = [{ id: 0, name: 'Vælg klubansvarlig' }]

        for (const member of this.membersForNewClub) {
          this.addManagerToClubOptions.push({
            name: member.user_id.firstname + ' ' + member.user_id.lastname + ' [' + (member.medlem_licens === null ? 'Ingen' : member.medlem_licens) + '] [ ' + (member.klubber_id === null ? 'Ingen' : member.klubber_id.klubber_klubnavn) + ']',
            id: Number(member.id)
          })
        }
        console.log('MembersOptionArray: ' + JSON.stringify(this.addManagerToClubOptions))
      })
  }

  public deleteOpeningHour (openingHourId: number) : void {
    console.log('Deleteing opening hour with id: ' + openingHourId)

    OpeningHoursDataService.delete(openingHourId.toString())
      .then((response) => {
        console.log(response.data)
        this.deleteOpeningHoursWarningModal = false
        this.retrieveOpeningHoursOfClub(this.lastEditClubId)
      })
      .catch((err) => {
        this.error = err
      })
  }

  public addOpeningHoursToClub () : void {
    if (this.openingHoursDayValue !== '' && this.openingData.open.length > 0 && this.openingData.close.length > 0 && this.openingData.open < this.openingData.close) {
      const addOpeningHoursData = {
        aabningstider_status: 1,
        aabningstider_aaben: this.openingData.open + ':00.000',
        aabningstider_luk: this.openingData.close + ':00.000',
        aabningstider_dag: this.parseEnum(this.openingHoursDayValue),
        klubber_id: this.lastEditClubId,
        aabningstider_kommentar: (this.openingData.comment === '' ? null : this.openingData.comment)
      }

      OpeningHoursDataService.create(addOpeningHoursData)
        .then((response) => {
          console.log(response.data)
          this.openingData = { open: '', close: '', day: '', clubId: 0, comment: '' }
          this.openingHoursDayValue = ''
          this.retrieveOpeningHoursOfClub(this.lastEditClubId)
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public editOpeningHours (clubId: number, clubName: string) : void {
    this.lastEditClubId = clubId
    this.lastEditClubName = clubName
    this.editOpeningHoursDrawerVisible = true
    this.retrieveOpeningHoursOfClub(this.lastEditClubId)
  }

  public retrieveOpeningHoursOfClub (clubId: number) : void {
    OpeningHoursDataService.getAll('', null, `klubber_id=${clubId}`)
      .then((response) => {
        this.openingHours = response.data
      })
      .catch((err) => {
        this.error = err
      })
  }

  public updateOpeningHour (index: number, id: number) : void {
    console.log(this.openingHours[index].aabningstider_aaben)
    if (this.openingHours[index].aabningstider_aaben < this.openingHours[index].aabningstider_luk) {
      const updateOpeningHoursData = {
        aabningstider_dag: (this.openingHours[index].aabningstider_dag.toString().includes('ø') ? this.parseEnum(this.openingHours[index].aabningstider_dag) : this.openingHours[index].aabningstider_dag),
        aabningstider_aaben: (this.openingHours[index].aabningstider_aaben.length < 12 ? this.openingHours[index].aabningstider_aaben + ':00.000' : this.openingHours[index].aabningstider_aaben),
        aabningstider_luk: (this.openingHours[index].aabningstider_luk.length < 12 ? this.openingHours[index].aabningstider_luk + ':00.000' : this.openingHours[index].aabningstider_luk),
        aabningstider_kommentar: (this.openingHours[index].aabningstider_kommentar === '' ? null : this.openingHours[index].aabningstider_kommentar)
      }
      OpeningHoursDataService.update(id.toString(), updateOpeningHoursData)
        .then((response) => {
          console.log(response.data)
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      alert('Fejl: Klubbens lukke tid kan ikke komme før dens åbningstid.')
    }

    console.log('Current day value: ' + this.openingHours[index].aabningstider_dag)
  }

  public resetMemberSearch () : void {
    this.currentMembersSorting = 'klubber_id.klubber_klubnavn:asc'
    this.extraMemberParameter = 'klubber_id.id=' + this.lastEditMembersOfKlubId.toString()
    this.memberSearchInputValue = ''
    this.memberSearchTerm = ''
    this.getMembersList()
  }

  public findMember () : void {
    console.log('Finding members')
    this.memberSearchTerm = this.memberSearchInputValue
    this.getMembersList()
  }

  public convertToSlug (text : string) : string {
    return CommonFunctions.slugify(text)
  }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange
    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage >= this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }
    this.retrieveClubs()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber
    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }
    this.retrieveClubs()
  }

  public columnSort (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = ''
      this.oldSort = ''
      this.clubSearchInputValue = ''
      this.clubSearchTerm = ''
    }
    if (sortBy === 'klubnavn') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_klubnavn:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_klubnavn:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'etableret') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_etableringsaar:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_etableringsaar:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'handicap') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_koerestolsvenlig:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_koerestolsvenlig:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'region') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'region_id.region_navn:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'region_id.region_navn:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'by') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'bypost_id.bypost_by:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'bypost_id.bypost_by:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'telefon') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_telefonnummer:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_telefonnummer:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'email') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_email:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_email:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'saldo') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_saldo:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_saldo:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'hjemmeside') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_hjemmeside:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_hjemmeside:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'facebook') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_facebook:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_facebook:desc'
        this.oldSort = ''
      }
    }
    this.retrieveClubs()
    CommonFunctions.scrollToTop()
  }

  public updatePostalCodeInputFieldDataFromCityInputFieldData (cityData: string) : void {
    if (cityData !== undefined && cityData !== null && cityData.length > 1) {
      ByPostDataService.findByCity(cityData, '', '1')
        .then((response) => {
          this.bypost = response.data
          if (this.bypost === undefined || this.bypost === null || this.bypost[0] === undefined) {
            this.currentByPostId = 0
          } else {
            this.inhibitUpdateCityInfo = true
            this.currentByPostId = (this.bypost[0].id !== null ? Number(this.bypost[0].id) : 0)
            console.log('Current bypost Id : ' + this.currentByPostId)
            this.klubData.klubPostnummer = (this.bypost[0].id !== null ? (this.bypost[0].bypost_postnummer.toString()) : '')
            this.inhibitUpdateCityInfo = false
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public updateCityInputFieldDataFromPostalCodeInputFieldData (postalCodeData: string) : void {
    if (postalCodeData !== undefined && postalCodeData !== null && Number(postalCodeData) > 799 && Number(postalCodeData) < 9999) {
      ByPostDataService.findByPostalnumber(Number(postalCodeData), '', '1')
        .then((response) => {
          this.bypost = response.data
          if (this.bypost === undefined || this.bypost === null || this.bypost[0] === undefined) {
            this.currentByPostId = 0
          } else {
            this.currentByPostId = (this.bypost[0].id !== null ? Number(this.bypost[0].id) : 0)
            console.log('Current bypost Id : ' + this.currentByPostId)
            this.klubData.klubBy = (this.bypost[0].id !== null ? (this.bypost[0].bypost_by) : '')
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public extractLogoURLFromClubLogoData (clubLogoData: any) : string {
    let logoURL = defaultMissingImgURL

    if (clubLogoData !== null) {
      // console.log('String output of klubber_logo data = ' + JSON.stringify(clubLogoData))
      // const logoObj = BaseFunctions.setLogoMediaObjFromStr(JSON.stringify(clubLogoData)) as LogoObj[]
      const logoObj = BaseFunctions.setLogoMediaObjFromObj(<any> clubLogoData) as LogoObj[]
      // logoURL = BaseFunctions.setLogoMediaObjFromStr(JSON.stringify(clubLogoData))[0].url
      logoURL = logoObj[0] === undefined || logoObj[0].url === null ? logoURL : mediaBaseURL + logoObj[0].url
      // console.log('Test current club logo media URL: ' + mediaBaseURL + logoURL)
    }

    return logoURL
  }

  public isMemberOfCurrentClub (memberClubId: number | null) : boolean {
    return (memberClubId === this.lastEditMembersOfKlubId)
  }

  public getNumberOfTeamCompetitionRegistrationsInCategory (teamIdObjArray: [{ id: number | null; raekke_id: number | null }] = [{ id: null, raekke_id: null }], tournamentCategoryId: number | null) : number {
    // hold_id.raekke.Turneringskategori_id
    let tournamentCategoryCount = 0

    for (const objItem of teamIdObjArray) {
      let arrIndex1 = 0

      for (const objItem2 of this.tempHolds) {
        // const arrIndex1 = this.tempHolds.findIndex(x => x.id === objItem.id)
        if (this.tempHolds[arrIndex1].id !== null && objItem.id !== null &&
          this.tempHolds[arrIndex1].raekke_id !== null && this.tempHolds[arrIndex1].raekke_id.id !== null &&
          objItem.raekke_id !== null && this.tempHolds[arrIndex1].id === objItem.id &&
          this.tempHolds[arrIndex1].raekke_id.id === objItem.raekke_id) {
          if (tournamentCategoryId !== null && (Number(this.tempHolds[arrIndex1].raekke_id.turneringskategori_id) === Number(tournamentCategoryId))) {
            // console.log('[getNumberOfTeamCompetitionRegistrationsInCategory] Found team tournament row and category match for row / category : ' + objItem.raekke_id + ' / ' + Number(tournamentCategoryId))
            tournamentCategoryCount++
          }
        }

        arrIndex1++
      }
    }

    return tournamentCategoryCount
  }

  public forceRerenderMemberList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public forceRerenderClubsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public findClub () : void {
    console.log('Search club.')
    this.clubSearchTerm = this.clubSearchInputValue
    this.retrieveClubs()
    CommonFunctions.scrollToTop()
  }

  public editClub (clubId : number) : void {
    console.log('Edit club with Id: ' + clubId)
    this.lastEditedKlubId = clubId

    console.log('Test base media URL: ' + mediaBaseURL)

    ClubsDataService.get(clubId.toString())
      .then((response) => {
        this.tempKlub = response.data
        console.log(response.data)

        this.klubData = {
          klubStatus: this.tempKlub.klubber_status,
          klubLogo: this.tempKlub.klubber_logo,
          klubKlubnavn: this.tempKlub.klubber_klubnavn,
          klubVejnavn: this.tempKlub.klubber_vejnavn,
          klubPostnummer: (this.tempKlub.bypost_id !== undefined && this.tempKlub.bypost_id !== null ? (this.tempKlub.bypost_id.bypost_postnummer !== null ? Number(this.tempKlub.bypost_id.bypost_postnummer).toString() : '0') : '0'),
          klubBy: (this.tempKlub.bypost_id !== undefined && this.tempKlub.bypost_id !== null ? (this.tempKlub.bypost_id.bypost_by !== null ? this.tempKlub.bypost_id.bypost_by : '') : ''),
          klubRegion: this.tempKlub.region_id.region_navn,
          klubTelefonnummer: (this.tempKlub.klubber_telefonnummer !== null ? this.tempKlub.klubber_telefonnummer.toString() : ''),
          klubEmail: this.tempKlub.klubber_email,
          klubBeskrivelse: this.tempKlub.klubber_klubbeskrivelse,
          klubAAben: this.tempKlub.klubber_aaben,
          klubSaldo: (this.tempKlub.klubber_saldo !== null ? this.tempKlub.klubber_saldo.toString() : ''),
          klubHjemmekampe: (this.tempKlub.klubber_hjemmekampe !== null ? this.tempKlub.klubber_hjemmekampe.toString() : ''),
          klubBaner: (this.tempKlub.klubber_baner !== null ? this.tempKlub.klubber_baner.toString() : ''),
          klubEtableringsaar: this.tempKlub.klubber_etableringsaar,
          klubKoerestolsvenlig: (this.tempKlub.klubber_koerestolsvenlig === WheelchairFriendlyType.Ja),
          klubFacebook: this.tempKlub.klubber_facebook,
          klubHjemmeside: this.tempKlub.klubber_hjemmeside,
          klubSlug: this.tempKlub.klubber_slug,
          klubAabningstider: (this.tempKlub.aabningstiders_id.length > 0 ? this.tempKlub.aabningstiders_id : ''),
          klubLicens: (this.tempKlub.klubber_kontingent_slut === null ? '1970-01-01' : this.tempKlub.klubber_kontingent_slut)
        }

        if (this.tempKlub.region_id !== undefined && this.tempKlub.region_id.id !== 0) {
          this.klubRegionTrackByValue = { name: this.tempKlub.region_id.region_navn, id: Number(this.tempKlub.region_id.id).toString() }
        }

        // Update, if possible, the contents of the city input field based upon the value of the postal code obtained from the last API access data result.
        if (this.tempKlub.bypost_id !== undefined && this.tempKlub.bypost_id !== null && this.tempKlub.bypost_id.bypost_postnummer !== null && !isNaN(Number(this.tempKlub.bypost_id.bypost_postnummer))) {
          this.updateCityInputFieldDataFromPostalCodeInputFieldData(Number(this.tempKlub.bypost_id.bypost_postnummer).toString())
        }

        const interval = setInterval(() => {
          if (this.$refs.clubDescription) {
            clearInterval(interval)
            this.$refs.clubDescription.setContents(this.klubData.klubBeskrivelse, 'user')
          }
        }, 100)

        this.oldClubName = this.klubData.klubKlubnavn
        this.editClubModal = true
      })
      .catch((err) => {
        this.error = err
      })
  }

  public changeSaldoClub (clubId : number) : void {
    console.log('Change saldo of club with Id: ' + clubId)
    this.editClubSaldoModal = true
    this.lastEditedKlubId = clubId

    ClubsDataService.get(clubId.toString())
      .then((response) => {
        this.tempKlub = response.data
        this.klubData = {
          klubStatus: this.tempKlub.klubber_status,
          klubLogo: this.tempKlub.klubber_logo,
          klubKlubnavn: this.tempKlub.klubber_klubnavn,
          klubVejnavn: this.tempKlub.klubber_vejnavn,
          klubPostnummer: (this.tempKlub.bypost_id.bypost_postnummer !== null ? this.tempKlub.bypost_id.bypost_postnummer.toString() : ''),
          klubBy: this.tempKlub.bypost_id.bypost_by,
          klubRegion: this.tempKlub.region_id.region_navn,
          klubTelefonnummer: (this.tempKlub.klubber_telefonnummer !== null ? this.tempKlub.klubber_telefonnummer.toString() : ''),
          klubEmail: this.tempKlub.klubber_email,
          klubBeskrivelse: this.tempKlub.klubber_klubbeskrivelse,
          klubAAben: this.tempKlub.klubber_aaben,
          klubSaldo: (this.tempKlub.klubber_saldo !== null ? this.tempKlub.klubber_saldo.toString() : ''),
          klubHjemmekampe: (this.tempKlub.klubber_hjemmekampe !== null ? this.tempKlub.klubber_hjemmekampe.toString() : ''),
          klubBaner: (this.tempKlub.klubber_baner !== null ? this.tempKlub.klubber_baner.toString() : ''),
          klubEtableringsaar: this.tempKlub.klubber_etableringsaar,
          klubKoerestolsvenlig: (this.tempKlub.klubber_koerestolsvenlig === WheelchairFriendlyType.Ja),
          klubFacebook: this.tempKlub.klubber_facebook,
          klubHjemmeside: this.tempKlub.klubber_hjemmeside,
          klubSlug: this.tempKlub.klubber_slug,
          klubAabningstider: (this.tempKlub.aabningstiders_id.length > 0 ? this.tempKlub.aabningstiders_id : ''),
          klubLicens: (this.tempKlub.klubber_kontingent_slut === null ? '1970-01-01' : this.tempKlub.klubber_kontingent_slut)
        }

        console.log(response.data)
      })
      .catch((err) => {
        this.error = err
      })
  }

  // public cancelClubEdit () : void {
  //   // this.klubData = { klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubFacebook: '', klubHjemmeside: '', klubSlug: '', klubAabningstider: [] }
  //   this.klubRegionTrackByValue = { name: 'Vælg her', id: '0' }
  //   this.retrieveClubs()
  // }

  // public cancelClubSaldoEdit () : void {
  //   this.klubData = { klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubFacebook: '', klubHjemmeside: '', klubSlug: '', klubAabningstider: [] }
  // }

  public blockClub (clubId : number) : void {
    console.log('Change status of club with Id: ' + clubId)

    ClubsDataService.get(clubId.toString())
      .then((response) => {
        this.tempKlub = response.data
        console.log(response.data)

        const updateClubData = {
          klubber_status: !this.tempKlub.klubber_status
        }

        console.log('New status of club = ' + updateClubData.klubber_status)

        ClubsDataService.update(clubId.toString(), updateClubData)
          .then((response) => {
            // this.lastUpdatedClubId = response.data.id
            console.log(response.data)
            const klubbersIndexToUpdateStatus = this.klubbers.findIndex(x => x.id === clubId)
            this.klubbers[klubbersIndexToUpdateStatus].klubber_status = updateClubData.klubber_status
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
        // location.reload()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public deleteClubWarning (clubId: number, clubName: string) : void {
    this.deleteClubId = clubId
    this.deleteClubName = clubName
    this.deleteClubWarningModal = true
  }

  public deleteClub (clubId : number) : void {
    console.log('Delete club with Id: ' + clubId)
    // First move any members of the club to be deleted to the Guestclub
    MembersDataService.getAll('', null, `klubber_id.id=${clubId.toString()}`)
      .then((response) => {
        this.tempMedlems = response.data

        if (this.tempMedlems.length > 0) {
          for (const member of this.tempMedlems) {
            const updateMemberData = {
              klubber_id: 22
            }
            MembersDataService.update(Number(member.id).toString(), updateMemberData)
              .then((response) => {
                console.log(response.data)
              })
              .catch((err) => {
                this.error = err
              })
          }
        }
      })
      .catch((err) => {
        this.error = err
      })
      .then(() => {
        // Then delete then club
        ClubsDataService.delete(clubId.toString())
          .then((response) => {
            console.log(response.data)
            this.deleteClubWarningModal = false
            this.retrieveClubs()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      })
  }

  public extraMemberInfo (memberId: number) : void {
    this.extraMemberModal = true
    this.addClubMembersDrawerVisible = false
    let tempRole = ''

    MembersDataService.get(memberId.toString())
      .then((response) => {
        this.tempMedlem = response.data
        console.log(this.tempMedlem)

        tempRole = this.convertClubRoleValueEnumToString(this.tempMedlem.medlem_klubrolle)
        if (tempRole.trim().startsWith('ae')) {
          tempRole.replace('ae', 'æ')
        }

        this.memberData = {
          brugerFornavn: this.tempMedlem.user_id.firstname,
          brugerEfternavn: this.tempMedlem.user_id.lastname,
          brugerTelefon: this.tempMedlem.user_id.phone.toString(),
          brugerEmail: this.tempMedlem.user_id.email,
          medlemFoedselsdag: this.tempMedlem.medlem_foedselsdag,
          medlemVejnummer: this.tempMedlem.medlem_vejnummer,
          medlemPostnummer: this.tempMedlem.bypost_id.bypost_postnummer,
          medlemBy: this.tempMedlem.bypost_id.bypost_by,
          paradartspillerCheck: this.tempMedlem.medlem_paradart,
          medlemKoen: this.tempMedlem.koen_id.Koen,
          medlemStatus: this.tempMedlem.medlem_status,
          medlemLicens: this.tempMedlem.medlem_licens,
          medlemBegyndelse: this.tempMedlem.medlem_begyndelse,
          medlemKarantaeneDato: (this.tempMedlem.medlem_karantaene_dato === null ? '1970-01-01' : this.tempMedlem.medlem_karantaene_dato),
          medlemKarantaeneSlutdato: (this.tempMedlem.medlem_karantaene_slutdato === null ? '1970-01-01' : this.tempMedlem.medlem_karantaene_slutdato),
          medlemKlubRolle: this.capitalize(tempRole)
        }
        console.log(this.memberData)
      })
  }

  public testActiveQuarantine (startDate: string, endDate: string) : boolean {
    const start = (startDate === null ? new Date('1970-01-01').toISOString() : new Date(startDate).toISOString())
    const end = (startDate === null ? new Date('1970-01-01').toISOString() : new Date(endDate).toISOString())
    const d = new Date().toISOString()
    if (start < d && end > d) {
      return true
    }
    return false
  }

  public calcAge (date : string) : number {
    const ageDiff = Date.now() - new Date(date).getTime()
    const ageDate = new Date(ageDiff)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  public convertClubRoleValueEnumToString (enumValue: any) : string {
    return (enumValue === null ? '' : enumValue.toString())
  }

  public capitalize (str: string) : string {
    const retVal = str.toLowerCase()
    return str.charAt(0).toUpperCase() + retVal.slice(1)
  }

  public editMembersClub (clubId : number) : void {
    console.log('Edit members in club with Id: ' + clubId)
    this.addClubMembersDrawerVisible = true
    this.lastEditMembersOfKlubId = clubId
    this.currentMembersSorting = 'klubber_id.klubber_klubnavn:asc'
    this.extraMemberParameter = 'klubber_id.id=' + clubId.toString()

    ClubsDataService.get(this.lastEditMembersOfKlubId.toString())
      .then((response) => {
        this.tempKlub = response.data
        console.log(response.data)
        this.lastEditMembersOfKlubManagerMemberId = (this.tempKlub.klubber_ansvarlig_id !== null && this.tempKlub.klubber_ansvarlig_id.id !== null ? Number(this.tempKlub.klubber_ansvarlig_id.id) : 0)
        this.lastEditMembersOfKlubName = (this.tempKlub.klubber_klubnavn !== null ? this.tempKlub.klubber_klubnavn : '')
        this.lastEditMembersOfKlubLogo = this.tempKlub.klubber_logo
        this.getMembersList()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public editTeamClub (clubId : number) : void {
    console.log('Edit team for club with Id: ' + clubId)
    this.lastEditMembersOfKlubId = clubId
    let nowDate = Date.now() // Fixme: obtain current date from a reliable server source instead of using browser klient date & time information to reduce likelyhood of manipulation or error. [DONE]

    // Try to get current date from a specified trustworthy server.
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        // console.log(Date.parse(((response.data).trim())))
        nowDate = ((response.data).trim().length > 9 ? Date.parse((response.data).trim()) : nowDate)
      })
      .catch((data) => {
        console.log('[editTeamClub] Failed fetching datetime from server: ' + JSON.stringify(data))
      })
      .finally(() => {
        SeasonsDataService.getAll('created_at:desc', null, 'saeson_status=true')
          .then((response) => {
            this.tempSeasons = response.data
            // console.log(response.data)

            // Find the season ID of the current season, that is active and where current time is legitimatly within the time period allowed for registrating at team for that season.
            for (const seasonItem of this.tempSeasons) {
              if (seasonItem.saeson_status === true) {
                // console.log('[editTeamClub] seasonItem.saeson_start = ' + Date.parse(seasonItem.saeson_start))
                // console.log('[editTeamClub] nowDate = ' + nowDate)

                if (Date.parse(seasonItem.saeson_start) <= nowDate && Date.parse(seasonItem.saeson_tilmelding) >= nowDate) {
                  this.currentTournamentSeasonId = (seasonItem.id === null ? 0 : Number(seasonItem.id))
                  this.currentTournamentSeasonStartDate = (seasonItem.id === null || seasonItem.saeson_start === null ? '1970-01-01' : seasonItem.saeson_start)
                }
              }
            }
            console.log('[editTeamClub] currentTournamentSeasonId = ' + this.currentTournamentSeasonId)

            ClubsDataService.get(this.lastEditMembersOfKlubId.toString())
              .then((response) => {
                this.tempKlub = response.data
                console.log(response.data)
                this.lastEditMembersOfKlubManagerMemberId = (this.tempKlub.klubber_ansvarlig_id !== null && this.tempKlub.klubber_ansvarlig_id.id !== null ? Number(this.tempKlub.klubber_ansvarlig_id.id) : 0)
                this.lastEditMembersOfKlubName = (this.tempKlub.klubber_klubnavn !== null ? this.tempKlub.klubber_klubnavn : '')
                this.lastEditMembersOfKlubLogo = this.tempKlub.klubber_logo
                // this.getMembersList()
                MembersDataService.getAll()
                  .then((response) => {
                    this.tempMedlems = response.data
                    // console.log(response.data)

                    TeamsDataService.getAll()
                      .then((response) => {
                        this.tempHolds = response.data
                        // console.log(response.data)

                        Tournaments.TournamentCategoriesDataService.getAll()
                          .then((response) => {
                            this.tempTournamentCategories = response.data
                            // console.log('[editTeamClub] tempTournamentCategories = ' + JSON.stringify(response.data))

                            // Set initial values for option selection box for the amount of teams to be added when ordering for each tournament category
                            this.showClubTeamMemberSelection = false
                            this.klubTeamMemberShowSelectionForThisCategory = []
                            this.klubTeamMemberNameValue = []
                            // this.klubTeamMemberNameOptions = [{ name: 'Vælg her', id: '0' }]
                            this.klubTeamMemberNameOptions = []

                            for (let loopIndex = 0; loopIndex < this.tempTournamentCategories.length; loopIndex++) {
                              this.klubTeamAddAmountValue[loopIndex] = { name: 'Vælg her', id: '0' }

                              if (this.tempTournamentCategories[loopIndex].turneringskategori_navn !== null && this.tempTournamentCategories[loopIndex].turneringskategori_navn.trim().toLocaleLowerCase().startsWith('juniorrækken')) {
                                this.klubTeamMemberShowSelectionForThisCategory[loopIndex] = true

                                if (this.showClubTeamMemberSelection === false) {
                                  // Iterate through the members list object, and add relvant members to the selection list object.
                                  for (let loopIndex2 = 0; loopIndex2 < this.tempMedlems.length; loopIndex2++) {
                                    // console.log('[editTeamClub] Klub Id = ' + (this.tempMedlems[loopIndex2].klubber_id !== null ? Number(this.tempMedlems[loopIndex2].klubber_id.id) : 'NULL'))
                                    // console.log('[editTeamClub] Medlem Id = ' + Number(this.tempMedlems[loopIndex2].id))
                                    // console.log('[editTeamClub] Bruger obj = ' + this.tempMedlems[loopIndex2].user_id)
                                    // console.log('[editTeamClub] Bruger Id = ' + (this.tempMedlems[loopIndex2].user_id !== undefined ? Number(this.tempMedlems[loopIndex2].user_id.id) : 'NULL'))

                                    // If the member is in the right club add the member to the selection list object.
                                    // Fixme: If the member is to be added to the 'juniorrækken' category then check age of member, and only add the member, if at season start the member is less than 18 years old. (This should actually be done on the API service system.)
                                    if (this.tempMedlems[loopIndex2].klubber_id !== null && Number(this.tempMedlems[loopIndex2].klubber_id.id) === clubId && this.tempMedlems[loopIndex2].user_id !== undefined && this.tempMedlems[loopIndex2].user_id.id !== null) {
                                      this.klubTeamMemberNameOptions.push({ name: (this.tempMedlems[loopIndex2].user_id.firstname + ' ' + this.tempMedlems[loopIndex2].user_id.lastname), id: Number(this.tempMedlems[loopIndex2].id).toString() })
                                    }
                                  }
                                } else {
                                  this.klubTeamMemberShowSelectionForThisCategory[loopIndex] = false
                                }
                                console.log('[editTeamClub] klubTeamMemberNameOptions' + JSON.stringify(this.klubTeamMemberNameOptions))

                                this.showClubTeamMemberSelection = true
                              }
                            }

                            this.addClubTeamsDrawerVisible = true
                          })
                          .catch((err) => {
                            this.error = err
                            this.addClubTeamsDrawerVisible = false
                          })
                      })
                      .catch((err) => {
                        this.error = err
                        this.addClubTeamsDrawerVisible = false
                      })
                  })
                  .catch((err) => {
                    this.error = err
                    this.addClubTeamsDrawerVisible = false
                  })
              })
              .catch((err) => {
                this.error = err
                this.addClubTeamsDrawerVisible = false
              })
          })
          .catch((err) => {
            this.error = err
          })
      })
  }

  public addTeamToClub (tournamentCategoryId: number, indexVal: number) : void {
    console.log('Adding team with category Id: ' + tournamentCategoryId)
    const indexOfTournaments = this.tempTournamentCategories.findIndex(x => x.id === tournamentCategoryId)
    console.log('indexOfTournaments = ' + indexOfTournaments)
    let tempPreviousSeasons: SysSaeson[] = []
    let checkTournamentNameRowName = ''
    let lowestTournamentRowIdForCurrentSeason = 0
    let currentTournamentRowId = 0
    const maxNumberOfTeamsToAdd = this.klubTeamMemberShowSelectionForThisCategory[indexVal] ? 1 : Number(this.klubTeamAddAmountValue[indexVal].id)
    const currentTeamName = (this.klubTeamMemberShowSelectionForThisCategory[indexVal] ? this.klubTeamMemberNameValue[indexVal].name : this.tempKlub.klubber_klubnavn)

    if (tournamentCategoryId === 3 /* 'Hovedturnering' */) {
      if (this.tempKlub.region_id.id === 1 || this.tempKlub.region_id.id === 2) {
        checkTournamentNameRowName = 'danmarksserien'
      } else {
        checkTournamentNameRowName = 'kval'
      }
    } else if (tournamentCategoryId === 5 /* 'Oldboys 35' */) {
      checkTournamentNameRowName = 'oldboys +35'
    } else if (tournamentCategoryId === 6 /* 'Oldboys 50' */) {
      checkTournamentNameRowName = 'oldboys +50'
    } else if (tournamentCategoryId === 8 /* 'Damepairs' */) {
      checkTournamentNameRowName = 'damepairs'
    }

    if (this.currentTournamentSeasonId > 0 && this.tempKlub.klubber_status === true && indexOfTournaments >= 0 && this.tempKlub.klubber_saldo >= (this.tempTournamentCategories[indexOfTournaments].turneringskategori_pris * maxNumberOfTeamsToAdd)) {
      // Fetch tournament rows information that matches best the various criterias.
      Tournaments.TournamentRowsDataService.findByTournamentRowStatus(true, 'id:desc', null, 'saeson_id=' + Number(this.currentTournamentSeasonId).toString() + '&turneringskategori_id.id=' + Number(tournamentCategoryId).toString() + '&child_null=true' + '&region_id.id=' + Number(this.tempKlub.region_id.id).toString() + '&raekke_navn_contains=' + checkTournamentNameRowName)
        .then((response) => {
          this.tempTournamentRows = response.data
          // console.log(response.data)

          lowestTournamentRowIdForCurrentSeason = (this.tempTournamentRows.length < 1 || this.tempTournamentRows[0].id === null ? 0 : Number(this.tempTournamentRows[0].id))
          console.log('[addTeamToClub] lowestTournamentRowIdForCurrentSeason = ' + lowestTournamentRowIdForCurrentSeason.toString())

          // Fetch data of previous season from API if it is available.
          SeasonsDataService.findPreviousSeason(false, true, this.currentTournamentSeasonStartDate, Number(tournamentCategoryId), '')
            .then((response) => {
              tempPreviousSeasons = response.data
              console.log('[addTeamToClub] tempPreviousSeasons = ' + JSON.stringify(response.data))

              let numberOfTeamsInPreviousSeason = 0
              let previousSeasonTournamentRowId = 0

              // Find the number of active teams of the club in the previous season, and also obtain the tournament row Id of the teams for later processing.
              if (tempPreviousSeasons.length > 0) {
                for (const tempPreviousSeasonsTeamsitem of tempPreviousSeasons[0].holds_id) {
                  if (tempPreviousSeasonsTeamsitem.hold_status && tempPreviousSeasonsTeamsitem.klubber_id === this.tempKlub.id) {
                    previousSeasonTournamentRowId = tempPreviousSeasonsTeamsitem.raekke_id
                    numberOfTeamsInPreviousSeason++
                  }
                }
              }

              // Select the correct row Id for the team add creation depending on various conditions.
              if (tempPreviousSeasons.length > 0) {
                if (numberOfTeamsInPreviousSeason === 0 || numberOfTeamsInPreviousSeason !== maxNumberOfTeamsToAdd) {
                  // Set tournament row to lowest row of the relevant region found in the current season.
                  currentTournamentRowId = lowestTournamentRowIdForCurrentSeason
                } else {
                  // Set current tournament row to be the same as the tournament row of the previous season.
                  currentTournamentRowId = previousSeasonTournamentRowId
                }
              } else {
                // Set tournament row to lowest row of the relevant region found in the current season.
                currentTournamentRowId = lowestTournamentRowIdForCurrentSeason
              }

              // Fixme: The creation of teams here and the adjustment of club saldo should be implemented in the API of the API-service system. This code block here must only be excuted in the administration portion and only by the administrator or other trustworthy person to prevent a significant potential for fraud.
              // Add the number of teams that has been specified. If a member was selected then it is alway a single team.
              for (let addCount = 0; addCount < maxNumberOfTeamsToAdd; addCount++) {
                // Create a new team entry
                const createClubTeamData = {
                  hold_status: true,
                  // hold_holdnavn: (this.klubTeamMemberShowSelectionForThisCategory[indexVal] ? this.klubTeamMemberNameValue[indexVal].name : this.tempKlub.klubber_klubnavn),
                  hold_holdnavn: currentTeamName,
                  raekke_id: currentTournamentRowId,
                  // turneringskategori.id: tournamentCategoryId,
                  saeson_id: this.currentTournamentSeasonId,
                  klubber_id: this.lastEditMembersOfKlubId
                }

                console.log('Attempt to create new club team with Id: ' + (1 + this.lastCreatedClubTeamId))

                TeamsDataService.create(createClubTeamData)
                  .then((response) => {
                    this.lastCreatedClubTeamId = response.data.id
                    console.log(response.data)

                    ClubsDataService.get(this.lastEditMembersOfKlubId.toString())
                      .then((response) => {
                        this.tempKlub = response.data
                        this.klubData.klubSaldo = (this.tempKlub.klubber_saldo !== null ? this.tempKlub.klubber_saldo.toString() : '')

                        console.log(response.data)
                        this.updateClubSaldo(this.lastEditMembersOfKlubId, -this.tempTournamentCategories[indexOfTournaments].turneringskategori_pris)
                        if (addCount >= maxNumberOfTeamsToAdd - 1) {
                          this.closeEditClubTeam()
                          this.retrieveClubs()
                        }
                      })
                      .catch((err) => {
                        this.error = err
                        if (addCount >= maxNumberOfTeamsToAdd - 1) {
                          this.closeEditClubTeam()
                        }
                        this.retrieveClubs()
                      })
                  })
                  .catch((err) => {
                    this.error = err
                    // console.log(err)
                    if (addCount >= maxNumberOfTeamsToAdd - 1) {
                      this.closeEditClubTeam()
                    }
                  })
              }
            })
            .catch((err) => {
              this.error = err
            })
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public closeEditClubTeam () : void {
    console.log('Closing team drawer.')

    this.addClubTeamsDrawerVisible = false
    this.klubTeamAddAmountValue = []
    this.klubTeamMemberNameValue = []
    this.klubTeamMemberNameOptions = []
  }

  public createClub () : void {
    const tempClubTelefon : string = this.klubData.klubTelefonnummer.toString().trim()
    const tempClubSaldo : string = this.klubData.klubSaldo.toString()
    const tempClubHjemmekampe : string = this.klubData.klubHjemmekampe.toString()
    const tempClubPostnummer : string = this.klubData.klubPostnummer.toString().trim()
    const tempClubBaner : string = this.klubData.klubBaner.toString()
    const tempClubSlug : string = this.convertToSlug(this.klubData.klubKlubnavn)

    if (this.klubData.klubKlubnavn.length > 1 && tempClubTelefon.length > 7 && !isNaN(Number(tempClubTelefon)) &&
      tempClubSaldo.length >= 0 && !isNaN(Number(tempClubSaldo)) &&
      tempClubHjemmekampe.length >= 0 && !isNaN(Number(tempClubHjemmekampe)) &&
      Number(tempClubHjemmekampe) > 0 && tempClubPostnummer.length > 2 &&
      !isNaN(Number(tempClubPostnummer)) && this.klubData.klubEmail.length > 1 &&
      BaseFunctions.isEmail(this.klubData.klubEmail) && this.klubData.klubVejnavn.length > 1) {
      const loader = new Loader({
        apiKey: BaseFunctions.defDercpyt(this.externalservices.gms_key),
        version: 'quarterly',
        libraries: ['places']
      })
      const geocodeAddress = (this.klubData.klubVejnavn.trim() + ',+' + this.klubData.klubBy.trim() + ' ' + this.klubData.klubPostnummer.trim() + ',+Danmark')

      loader.load().then(() => {
        // Fetch information about the geographical location of the full address.
        const geocoderClass = new google.maps.Geocoder()
        console.log('[updateClub] Address: ' + geocodeAddress)
        // const geocodeDataProperties = { address: 'Charlotteager 156, kld.,+Hedehusene 2640,+Danmark' } as google.maps.GeocoderRequest
        const geocodeDataProperties = { address: geocodeAddress } as google.maps.GeocoderRequest
        // const promise = geocoderClass.geocode({ geocodeDataProperties, key: BaseFunctions.defDercpyt(this.externalservices.gms_key) })
        const promise = geocoderClass.geocode(geocodeDataProperties)
        let geocodeGeometryLocationLat = 0
        let geocodeGeometryLocationLng = 0

        promise
          .then((response) => {
            console.log(response.results)
            geocodeGeometryLocationLat = response.results[0].geometry.location.lat()
            geocodeGeometryLocationLng = response.results[0].geometry.location.lng()
            console.log('[updateClub] geocode result lat / lng = ' + geocodeGeometryLocationLat + ' / ' + geocodeGeometryLocationLng)
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            console.log('[updateClub] Geocode API fetch done.')

            ClubsDataService.getAll('', null, `klubber_klubnavn=${this.klubData.klubKlubnavn}`)
              .then((response) => {
                if (response.data.length > 0) {
                  this.$Message.danger({ text: 'Fejl: Der er allerede en klub med det navn. Vælg et andet, og forsøg igen' })
                } else {
                  const createClubData = {
                    klubber_status: this.klubData.klubStatus,
                    klubber_klubnavn: this.klubData.klubKlubnavn,
                    klubber_klubbeskrivelse: (this.klubData.klubBeskrivelse.trim() === '' ? this.defaultClubDescription : this.klubData.klubBeskrivelse),
                    klubber_vejnavn: this.klubData.klubVejnavn,
                    bypost_id: this.currentByPostId,
                    region_id: this.klubRegionTrackByValue.id,
                    klubber_telefonnummer: tempClubTelefon,
                    klubber_email: this.klubData.klubEmail,
                    klubber_aaben: this.klubData.klubAAben,
                    klubber_saldo: tempClubSaldo,
                    klubber_hjemmekampe: tempClubHjemmekampe,
                    klubber_etableringsaar: new Date(),
                    klubber_koerestolsvenlig: (this.klubData.klubKoerestolsvenlig ? 'Ja' : 'Nej'),
                    klubber_baner: tempClubBaner,
                    klubber_longitude: geocodeGeometryLocationLng,
                    klubber_latitude: geocodeGeometryLocationLat,
                    klubber_hjemmeside: this.klubData.klubHjemmeside, // Fixme Add rules for Hjemmeside and Facebook
                    klubber_facebook: this.klubData.klubFacebook, // Fixme Add rules for Hjemmeside and Facebook
                    klubber_slug: tempClubSlug,
                    klubber_ansvarlig_id: (this.addManagerToClubValue.id === 0 ? null : this.addManagerToClubValue.id)
                  }

                  console.log('Attempt to create new club with Id: ' + (1 + this.lastCreatedClubId))
                  // console.log('bypost_id = ' + this.currentByPostId)
                  // console.log('region_id = ' + this.klubRegionTrackByValue.id)

                  ClubsDataService.create(createClubData)
                    .then((response) => {
                      this.lastCreatedClubId = response.data.id
                      console.log(response.data)
                      this.submitted = true
                      this.retrieveClubs()

                      if (this.addManagerToClubValue.id !== 0) {
                        const updateMemberData = {
                          klubber_id: Number(this.lastCreatedClubId)
                        }

                        MembersDataService.update(Number(this.addManagerToClubValue.id).toString(), updateMemberData)
                          .then((response) => {
                            console.log(response.data)
                          })
                          .catch((err) => {
                            this.error = err
                          })
                      }

                      if (this.openingHoursArray.length > 0) {
                        for (const item of this.openingHoursArray) {
                          const createOpeningHoursData = {
                            aabningstider_status: 1,
                            aabningstider_aaben: item.open + ':00:000',
                            aabningstider_luk: item.close + ':00:000',
                            aabningstider_dag: this.parseEnum(item.day),
                            klubber_id: Number(this.lastCreatedClubId),
                            aabningstider_kommentar: (item.comment === '' ? null : item.comment)
                          }
                          OpeningHoursDataService.create(createOpeningHoursData)
                            .then((response) => {
                              console.log('Creating new opening hours with id: ' + response.data.id + ' for club: ' + this.lastCreatedClubId)
                            })
                            .catch((err) => {
                              this.error = err
                            })
                        }
                      }
                      this.$Message.success({ text: 'Klubben er blevet oprettet' })
                      this.newCreateClub()
                      this.tabValue = 'Vis og rediger klubber'
                    })
                    .catch((err) => {
                      this.error = err
                      console.log(err)
                    })
                }
              })
              .catch((err) => {
                this.error = err
                console.log(err)
              })
          })
          .catch((err) => {
            this.error = err
            console.log(err)
          })
      })
    }
  }

  public newCreateClub () : void {
    this.submitted = false
    this.klubData = { klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubFacebook: '', klubHjemmeside: '', klubSlug: '', klubAabningstider: [], klubLicens: '' }
    this.klubRegionTrackByValue = { name: 'Vælg her', id: '0' }
    document.removeEventListener('keyup', (e) => {
      if (e.key === 'Backspace' && e.key.length !== 0) {
        this.memberSearchInputString = this.memberSearchInputString.substring(0, this.memberSearchInputString.length - 1)
      } else {
        this.memberSearchInputString += e.key
      }
      console.log(this.memberSearchInputString)
    })
    this.memberSearchInputString = ''
    this.retrieveClubs()
  }

  // public newUpdatedClub () : void {
  //   this.klubData = { klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubFacebook: '', klubHjemmeside: '', klubSlug: '', klubAabningstider: [] }
  //   this.klubRegionTrackByValue = { name: 'Vælg her', id: '0' }
  //   this.oldClubName = ''
  //   this.memberSearchInputString = ''
  // }

  public updateClub () : boolean {
    console.log('Update information of user with Id: ' + this.lastEditedKlubId)
    const tempClubTelefon : string = this.klubData.klubTelefonnummer.toString().trim()
    const tempClubHjemmekampe : string = this.klubData.klubHjemmekampe.toString()
    const tempClubPostnummer : string = this.klubData.klubPostnummer.toString().trim()
    const tempClubBaner : string = this.klubData.klubBaner.toString()
    const tempClubSlug : string = this.convertToSlug(this.klubData.klubKlubnavn)

    if (this.klubData.klubKlubnavn.length > 1 && tempClubTelefon.length > 7 && !isNaN(Number(tempClubTelefon)) &&
      tempClubHjemmekampe.length >= 0 && !isNaN(Number(tempClubHjemmekampe)) &&
      Number(tempClubHjemmekampe) > 0 && tempClubBaner.length >= 0 && !isNaN(Number(tempClubBaner)) &&
      tempClubPostnummer.length > 2 && !isNaN(Number(tempClubPostnummer)) &&
      this.klubData.klubEmail.length > 1 && BaseFunctions.isEmail(this.klubData.klubEmail) &&
      this.klubData.klubVejnavn.length > 1 && Number(this.klubRegionTrackByValue.id) !== 0) {
      const loader = new Loader({
        apiKey: BaseFunctions.defDercpyt(this.externalservices.gms_key),
        version: 'quarterly',
        libraries: ['places']
      })
      const geocodeAddress = (this.klubData.klubVejnavn.trim() + ',+' + this.klubData.klubBy.trim() + ' ' + this.klubData.klubPostnummer.trim() + ',+Danmark')

      loader.load().then(() => {
        // Fetch information about the geographical location of the full address.
        const geocoderClass = new google.maps.Geocoder()
        console.log('[updateClub] Address: ' + geocodeAddress)
        // const geocodeDataProperties = { address: 'Charlotteager 156, kld.,+Hedehusene 2640,+Danmark' } as google.maps.GeocoderRequest
        const geocodeDataProperties = { address: geocodeAddress } as google.maps.GeocoderRequest
        // const promise = geocoderClass.geocode({ geocodeDataProperties, key: BaseFunctions.defDercpyt(this.externalservices.gms_key) })
        const promise = geocoderClass.geocode(geocodeDataProperties)
        let geocodeGeometryLocationLat = 0
        let geocodeGeometryLocationLng = 0

        promise
          .then((response) => {
            console.log(response.results)
            geocodeGeometryLocationLat = response.results[0].geometry.location.lat()
            geocodeGeometryLocationLng = response.results[0].geometry.location.lng()
            console.log('[updateClub] geocode result lat / lng = ' + geocodeGeometryLocationLat + ' / ' + geocodeGeometryLocationLng)
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            console.log('[updateClub] Geocode API fetch done.')

            const updateClubData = {
              klubber_status: this.klubData.klubStatus,
              klubber_klubnavn: this.klubData.klubKlubnavn,
              klubber_klubbeskrivelse: (this.klubData.klubBeskrivelse.trim() === '' ? this.defaultClubDescription : this.klubData.klubBeskrivelse),
              klubber_vejnavn: this.klubData.klubVejnavn,
              bypost_id: this.currentByPostId,
              region_id: this.klubRegionTrackByValue.id,
              klubber_telefonnummer: tempClubTelefon,
              klubber_email: this.klubData.klubEmail,
              klubber_aaben: this.klubData.klubAAben,
              klubber_hjemmekampe: Number(tempClubHjemmekampe),
              klubber_baner: Number(tempClubBaner),
              // klubber_etableringsaar: this.klubData.klubEtableringsaar,
              klubber_koerestolsvenlig: (this.klubData.klubKoerestolsvenlig ? 'Ja' : 'Nej'),
              klubber_saldo: this.klubData.klubSaldo,
              klubber_longitude: geocodeGeometryLocationLng,
              klubber_latitude: geocodeGeometryLocationLat,
              klubber_hjemmeside: this.klubData.klubHjemmeside,
              klubber_facebook: this.klubData.klubFacebook, // Fixme Add rules for hjemmeside and facebook
              klubber_slug: tempClubSlug
            }

            if (this.oldClubName !== this.klubData.klubKlubnavn) {
              ClubsDataService.getAll('', null, `klubber_klubnavn=${this.klubData.klubKlubnavn}`)
                .then((response) => {
                  if (response.data.length > 0) {
                    this.$Message.danger({ text: 'Fejl: Der eksister allerede en klub med det navn, vælg et andet forsøg igen' })

                    return false
                  } else {
                    ClubsDataService.update(this.lastEditedKlubId.toString(), updateClubData)
                      .then((response) => {
                        // this.lastUpdatedClubId = response.data.id
                        console.log(response.data)

                        // this.newUpdatedClub()
                        this.retrieveClubs()
                        this.editClubModal = false
                        this.$Message.success({ text: 'Klubben er blevet opdateret' })

                        return true
                      })
                      .catch((err) => {
                        this.error = err
                        console.log(err)

                        // this.klubData = { klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubFacebook: '', klubHjemmeside: '', klubSlug: '' }
                        // this.klubRegionTrackByValue = { name: 'Vælg her', id: '0' }
                        this.retrieveClubs()
                      })
                  }
                })
                .catch((err) => {
                  this.error = err
                  console.log(err)
                })
            } else {
              ClubsDataService.update(this.lastEditedKlubId.toString(), updateClubData)
                .then((response) => {
                  // this.lastUpdatedClubId = response.data.id
                  console.log(response.data)

                  // this.newUpdatedClub()
                  this.retrieveClubs()
                  this.editClubModal = false
                  this.$Message.success({ text: 'Klubben er blevet opdateret' })

                  return true
                })
                .catch((err) => {
                  this.error = err
                  console.log(err)

                  // this.klubData = { klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubFacebook: '', klubHjemmeside: '', klubSlug: '' }
                  // this.klubRegionTrackByValue = { name: 'Vælg her', id: '0' }
                  // this.retrieveClubs()
                })
            }

            // this.editClubModal = false
          })
      })

      return false
    }

    this.editClubModal = false
    this.retrieveClubs()
    // this.klubData = { klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubFacebook: '', klubHjemmeside: '', klubSlug: '', klubAabningstider: [], klubLicens: '' }
    // this.klubRegionTrackByValue = { name: 'Vælg her', id: '0' }

    return false
  }

  public updateClubSaldo (clubID = this.lastEditedKlubId, amountAdjustment = 0) : void {
    console.log('Update information of user with Id: ' + clubID)
    const tempClubSaldo : string = (Number(this.klubData.klubSaldo) + amountAdjustment).toString()

    if (tempClubSaldo.length >= 0 && !isNaN(Number(tempClubSaldo))) {
      const updateClubData = {
        klubber_saldo: tempClubSaldo
      }

      ClubsDataService.update(clubID.toString(), updateClubData)
        .then((response) => {
          // this.lastUpdatedClubId = response.data.id
          console.log(response.data)

          // this.klubData = { klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubFacebook: '', klubHjemmeside: '', klubSlug: '', klubAabningstider: [], klubLicens: '' }
          this.$Message.success({ text: 'Saldo opdateret' })
          this.editClubSaldoModal = false
          this.retrieveClubs()
        })
        .catch((err) => {
          this.error = err
          console.log(err)
        })
    }

    // this.klubData = { klubFacebook: '', klubHjemmeside: '', klubStatus: true, klubLogo: '', klubKlubnavn: '', klubVejnavn: '', klubPostnummer: '', klubBy: '', klubRegion: '', klubTelefonnummer: '', klubEmail: '', klubBeskrivelse: '', klubAAben: '', klubSaldo: '0', klubHjemmekampe: '1', klubBaner: '0', klubEtableringsaar: '', klubKoerestolsvenlig: false, klubSlug: '', klubAabningstider: [], klubLicens: '' }
  }

  public addMemberToClub (memberId : number, removeFlag = false) : void {
    console.log('Add or remove member to/from club ' + this.lastEditMembersOfKlubId + ' .')

    if (memberId > 0 && this.lastEditMembersOfKlubId) {
      // If the member is a manager of a club then remove the member as a manager first
      this.setMemberClubManager(memberId, true, false, true)

      const updateMemberData = {
        klubber_id: (removeFlag ? 22 : this.lastEditMembersOfKlubId) // If the member is removed from his club he is added to "Gæsteklubben"
      }

      MembersDataService.update(memberId.toString(), updateMemberData)
        .then((response) => {
          console.log(response.data)
          this.getMembersList()
        })
        .catch((err) => {
          this.error = err
          console.log(err)
        })
    }
  }

  public removeMemberFromClub (memberId : number) : void {
    this.addMemberToClub(memberId, true)
  }

  public setMemberClubManager (memberId : number, removeFlag = false, toggleFlag = false, memberOnlyRemoveFlag = false) : void {
    console.log('Set or unset member manager of the club ' + this.lastEditMembersOfKlubId + ' .')
    // console.log('removeFlag: ' + removeFlag)
    // console.log('toggleFlag: ' + toggleFlag)
    // console.log('memberOnlyRemoveFlag: ' + memberOnlyRemoveFlag)
    // BUG: A new club manager does not gain any admin rights associated with the job. Also when remembering
    // to change the manager. Remove the rights from the old manager.

    if (memberId > 0 && this.lastEditMembersOfKlubId) {
      if (toggleFlag) {
        ClubsDataService.get(this.lastEditMembersOfKlubId.toString())
          .then((response) => {
            this.tempKlub = response.data
            console.log(response.data)
            const tempKlubDataAnsvarlingId = (this.tempKlub.klubber_ansvarlig_id === null || this.tempKlub.klubber_ansvarlig_id.id !== memberId ? memberId : null)
            this.updateClubMemberManager(tempKlubDataAnsvarlingId)
          })
          .catch((err) => {
            this.error = err
          })
      } else {
        if (removeFlag && memberOnlyRemoveFlag) {
          // If flags indicate special removal of member as a manager of a club, then fetch the most recent relevant information about the member and the club of the member, and remove only if the member is actually a manager of that club
          MembersDataService.get(memberId.toString())
            .then((response) => {
              this.tempMedlem = response.data
              console.log(response.data)

              if (this.tempMedlem.klubber_id !== null && Number(this.tempMedlem.klubber_id.id) > 0) {
                ClubsDataService.get(Number(this.tempMedlem.klubber_id.id).toString())
                  .then((response) => {
                    this.tempKlub = response.data
                    // console.log('setMemberClubManager - got club data.')
                    console.log(response.data)

                    if (this.tempKlub.klubber_ansvarlig_id !== null &&
                        (Number(this.tempKlub.klubber_ansvarlig_id.id) === memberId ||
                          (Number(this.tempKlub.klubber_ansvarlig_id.id) > 0 &&
                            Number(this.tempMedlem.klubber_id.id !== this.lastEditMembersOfKlubId)))) {
                      this.updateClubMemberManager(null, Number(this.tempMedlem.klubber_id.id))
                    }
                  })
              }
            })
        } else {
          this.updateClubMemberManager((removeFlag ? null : memberId))
        }
      }
    }
  }

  public updateClubMemberManager (clubDataMemberId : number | null, clubId = 0) : void {
    const updateClubData = {
      klubber_ansvarlig_id: clubDataMemberId
    }

    // console.log('updateClubMemberManager clubId : ' + clubId)

    ClubsDataService.update((clubId === 0 ? this.lastEditMembersOfKlubId.toString() : clubId.toString()), updateClubData)
      .then((response) => {
        // this.lastUpdatedClubId = response.data.id
        console.log(response.data)
        if (clubId === 0) {
          this.lastEditMembersOfKlubManagerMemberId = (clubDataMemberId !== null ? clubDataMemberId : 0)
        }
        // console.log('updateClubMemberManager last memberId : ' + this.lastEditMembersOfKlubManagerMemberId)
        this.getMembersList()
      })
      .catch((err) => {
        this.error = err
        console.log(err)
      })
  }

  public getMembersList () : void {
    console.log('Fetching members')
    if (this.memberSearchTerm.length > 0) {
      MembersDataService.findByNameAndLicenseSearchTerm(this.memberSearchTerm, this.currentMembersSorting, null)
        .then((response) => {
          this.tempMedlems = response.data
          console.log(this.tempMedlems)
          this.forceRerenderMemberList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      MembersDataService.getAll(this.currentMembersSorting, null, this.extraMemberParameter)
        .then((response) => {
          this.tempMedlems = response.data
          console.log(this.tempMedlems)
          this.forceRerenderMemberList()
          this.$forceUpdate()

          // console.log(response.data)
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public retrieveExternalServices () : void {
    console.log('Fetching region data from API.')

    ExternalServicesDataService.get('1')
      .then((response) => {
        this.externalservices = response.data
        console.log(response.data)
        // console.log('[retrieveExternalServices] value 2 = ' + BaseFunctions.defDercpyt(this.externalservices.gms_key))
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public retrieveRegions () : void {
    console.log('Fetching region data from API.')

    RegionsDataService.getAll()
      .then((response) => {
        this.regions = response.data
        console.log(response.data)

        // Fill the region name options array
        this.klubRegionTrackByOptions = [{ name: 'Vælg her', id: '0' }]
        this.klubRegionFilterOptions = [{ name: 'Vis alt', id: '0' }]

        for (const region of this.regions) {
          this.klubRegionTrackByOptions.push({ name: region.region_navn, id: (region.id === undefined ? '0' : Number(region.id).toString()) })
          this.klubRegionFilterOptions.push({ name: region.region_navn, id: (region.id === undefined ? '0' : Number(region.id).toString()) })
          this.exportRegionFilterOptions.push({ name: region.region_navn, id: (region.id === undefined ? 0 : Number(region.id)) })
          // console.log('Region names = ' + JSON.stringify(this.klubRegionTrackByOptions))
        }
        this.exportRegionFilterOptions.sort(function (a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })

        this.klubRegionFilterOptions.sort(function (a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
      })
      .catch((err) => {
        this.error = err
        // console.log(err)
      })
  }

  public retrieveClubs () : void {
    console.log('Counting Clubs')

    let countFilter = ''

    if (this.filterStatusStringValue === 'Vis alt') {
      countFilter = this.extraParameter
    } else if (this.filterStatusStringValue === 'Aktiv licens') {
      countFilter = 'klubber_status=true&' + this.extraParameter
    } else {
      countFilter = 'klubber_status=false&' + this.extraParameter
    }

    ClubsDataService.getCount(countFilter)
      .then((response) => {
        this.totalPages = response.data
        this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
        console.log(this.currentPage + ' of ' + this.totalNumberOfPages + ' - total number of entries: ' + this.totalPages)
      })
      .catch((err) => {
        this.error = err
      })

    console.log('Fetching clubs data from API.')

    if (this.clubSearchTerm.length > 0) {
      ClubsDataService.findBySearchTerm((this.filterStatusStringValue === 'Aktiv licens'), (this.filterStatusStringValue === 'Vis alt'), this.clubSearchTerm, this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, this.extraParameter)
        .then((response) => {
          this.klubbers = response.data
          // console.log(response.data)
          this.forceRerenderClubsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    } else {
      if (this.filterStatusStringValue === 'Vis alt') {
        ClubsDataService.getAll(this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, this.extraParameter)
          .then((response) => {
            this.klubbers = response.data
            // console.log(response.data)
            this.forceRerenderClubsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      } else {
        ClubsDataService.findByClubStatus((this.filterStatusStringValue === 'Aktiv licens'), this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, this.extraParameter)
          .then((response) => {
            this.klubbers = response.data
            // console.log(response.data)
            this.forceRerenderClubsList()
            this.$forceUpdate()
          })
          .catch((err) => {
            this.error = err
            // console.log(err)
          })
      }
    }
  }

  public retrieveExportData () : void {
    this.exportDropDown = true
    this.loadingDataFlag = true

    ClubsDataService.getAll('', null, '')
      .then((response) => {
        this.exportClubs = response.data
        MembersDataService.getAll('', null, '')
          .then((response) => {
            this.tempMembers = response.data
            this.exportClubs.map((item: any) => {
              const id = (item.id === null ? 0 : item.id)
              const newProp = {
                count: this.tempMembers.filter((element) => { return ((element.klubber_id === null ? 0 : element.klubber_id.id === id)) }).length
              }

              return Object.assign(item, newProp)
            })
            this.tempMembers.map((item: any) => {
              const newProp = {
                memberAge: this.calcAge(item.medlem_foedselsdag)
              }

              return Object.assign(item, newProp)
            })

            console.log(this.exportClubs)
            console.log(this.tempMembers)
            this.loadingDataFlag = false
          })
      })
      .catch((err) => {
        this.error = err
      })
  }

  public isMemberInAgeRange (objArray: { id: number | null, medlem_foedselsdag: string, klubber_id: { id: number | null } }[] = [], clubId: number, lowerAgeValue: number, upperAgeValue: number) : boolean {
    for (const objItem of objArray) {
      if ((objItem.klubber_id === null ? 0 : objItem.klubber_id.id) === clubId && this.calcAge(objItem.medlem_foedselsdag) >= lowerAgeValue && (this.calcAge(objItem.medlem_foedselsdag) < upperAgeValue || upperAgeValue >= 100)) {
        return true
      }
    }

    return false
  }

  public async exportDataToExcel () : Promise<void> {
    if (this.exportFieldsFilterValue.length === 0) {
      return
    }
    function subtrackYears (date: Date, years: number) : Date {
      date.setFullYear(date.getFullYear() - years)
      return date
    }
    let tempExportItem: any[] = []
    let tempExportArray: any[] = []
    this.loadingDataFlag = true

    let memberParam = ''

    if (this.exportRegionFilterValue.id !== 0) {
      memberParam += (memberParam.length > 0 ? '&' : '') + `klubber_id.region_id=${this.exportRegionFilterValue.id}`
    }
    if (this.ageRange[0] > 0) {
      memberParam += (memberParam.length > 0 ? '&' : '') + `medlem_foedselsdag_lte=${subtrackYears(new Date(), this.ageRange[0]).toISOString().split('T')[0]}`
    }
    if (this.ageRange[1] < 100) {
      memberParam += (memberParam.length > 0 ? '&' : '') + `medlem_foedselsdag_gte=${subtrackYears(new Date(), this.ageRange[1]).toISOString().split('T')[0]}`
    }

    console.log(memberParam)

    const tempClubsPromise = ClubsDataService.getAll('', null, (this.exportRegionFilterValue.id === 0 ? '' : `region_id.id=${this.exportRegionFilterValue.id}`))
    const tempMembersPromise = MembersDataService.getAll('', null, memberParam)

    await Promise.all([tempClubsPromise, tempMembersPromise])
      .then((response) => {
        const tempClubs = response[0].data as any[]
        const tempMembers = response[1].data as SysMember[]
        tempClubs.map((item: any) => {
          const id = (item.id === null ? 0 : item.id)
          const newProp = {
            count: tempMembers.filter((el) => { return ((el.klubber_id === null ? 0 : Number(el.klubber_id.id) === Number(id))) }).length
          }
          return Object.assign(item, newProp)
        })

        for (const filterItem of this.exportFieldsFilterValue) {
          tempExportItem = tempClubs.map(item => {
            return {
              [filterItem.id]: item[filterItem.id], [filterItem.name]: item[filterItem.value]
            }
          })

          if (tempExportArray.length === 0) {
            tempExportArray = tempExportItem
          } else {
            const arr3 = tempExportArray.map((item, i) => Object.assign({}, item, tempExportItem[i]))
            tempExportArray = arr3
          }
        }

        const data = tempExportArray
        const fileName = 'klubber_eksport'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
        this.loadingDataFlag = false
        this.exportDropDown = false
      })
      .catch((err) => {
        console.log(err)
      })

    // if (this.loadingDataFlag === false && this.exportFieldsFilterValue.length > 0) {
    //   if (this.exportRegionFilterValue.id !== 0) {
    //     this.exportClubs = this.exportClubs.filter((element: any) => { return element.region_id.id === this.exportRegionFilterValue.id })
    //   }

    //   if (this.ageRange[0] > 0 || this.ageRange[1] < 100) {
    //     this.exportClubs.map((item: any) => {
    //       const id = (item.id === null ? 0 : item.id)
    //       const newProp = {
    //         haveMemberInAgeRange: this.isMemberInAgeRange(this.tempMembers, id, this.ageRange[0], this.ageRange[1])
    //       }
    //       return Object.assign(item, newProp)
    //     })

    //     console.log(this.exportClubs)
    //     this.exportClubs = this.exportClubs.filter((element: any) => { return element.haveMemberInAgeRange === true })
    //   }

    //   for (const filterItem of this.exportFieldsFilterValue) {
    //     tempExportItem = this.exportClubs.map(item => {
    //       return {
    //         [filterItem.id]: item[filterItem.id], [filterItem.name]: item[filterItem.value]
    //       }
    //     })

    //     if (tempExportArray.length === 0) {
    //       tempExportArray = tempExportItem
    //     } else {
    //       const arr3 = tempExportArray.map((item, i) => Object.assign({}, item, tempExportItem[i]))
    //       tempExportArray = arr3
    //     }
    //   }
    //   console.log(tempExportArray)

    //   const data = tempExportArray
    //   const fileName = 'klubber_eksport'
    //   const exportType = exportFromJSON.types.xls

    //   exportFromJSON({ data, fileName, exportType })
    //   this.exportDropDown = false
    // }
  }

  public retrieveDate () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        // this.todaysDate = new Date(response.data.split('T')[0]).toISOString().split('T')[0]
        this.todaysDate = new Date(response.data).toISOString().split('T')[0]
        // this.nowDate = ((response.data).trim().length > 9 ? Date.parse((response.data).trim()) : this.nowDate)
      })
      .catch((err) => {
        this.error = err
      })
  }

  // Methods for handeling images
  @Watch('imageUpload')
  onImageUploadChange () : void {
    // if (this.imageUpload !== undefined && this.imageUpload.length > 0) {
    if (this.imageUpload !== undefined && JSON.stringify(<File> this.imageUpload.size) !== null && Number((<File> this.imageUpload).size) > 0) {
      const fd: FormData = new FormData()

      fd.append('files', this.imageUpload)

      MediasDataService.upload(fd)
        .then((response) => {
          console.log(response.data)
          this.retrieveImages()
          this.imageUpload = []
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  @Watch('displayImagesModal')
  onDisplayModalChange (toggleValue: false) : void {
    if (!toggleValue) {
      this.imageSearchInputValue = ''
      this.imageSearchTerm = ''
      this.imageUpload = []
      this.selectedImage = []
    }
  }

  public setNewClubLogo () : void {
    let updateClub = {}
    if (this.selectedImage.length === 0) {
      updateClub = {
        klubber_logo: []
      }
    } else {
      updateClub = {
        klubber_logo: this.selectedImage
      }
    }

    ClubsDataService.update(this.lastEditedKlubId.toString(), updateClub)
      .then((response) => {
        console.log(response.data)
        this.$Message.success({ text: 'Nyt klublog valgt' })
        this.displayImagesModal = false
        this.editClub(this.lastEditedKlubId)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public uncheck (val: any[]) : void {
    if (val === this.selectedImage) {
      this.selectedImage = []
    }
  }

  public newClubLogo () : void {
    this.retrieveImages()
    if (this.klubData.klubLogo.length > 0) {
      this.selectedImage = this.klubData.klubLogo[0]
      console.log(this.selectedImage)
    }
    this.displayImagesModal = true
  }

  public turnToNewImagePage (pageChange: number) {
    this.currentImagePage += pageChange
    if (this.currentImagePage < 1) {
      this.currentImagePage = 1
    }
    if (this.currentImagePage > this.totalNumberOfImagePages) {
      this.currentImagePage = this.totalNumberOfImagePages
    }
    this.retrieveImages()
  }

  public jumpToImagePage (pageNumber: number) {
    this.currentImagePage = pageNumber
    if (this.currentImagePage < 1) {
      this.currentImagePage = 1
    }
    if (this.currentImagePage > this.totalNumberOfImagePages) {
      this.currentImagePage = this.totalNumberOfImagePages
    }
    this.retrieveImages()
  }

  public resetSearch () : void {
    console.log('Reseting search')
    this.imageSearchInputValue = ''
    this.imageSearchTerm = ''
    this.retrieveImages()
  }

  public findImage () : void {
    console.log('Searching images')
    this.imageSearchTerm = this.imageSearchInputValue
    this.retrieveImages()
  }

  public getImage (imageData: any) : string {
    if (imageData.length === 0) {
      return defaultMissingImgURL
    }
    const imageURL = defaultImageURL + imageData.url
    if (imageURL === undefined || imageURL === null) {
      return defaultMissingImgURL
    }
    return imageURL
  }

  public retrieveImages () : void {
    console.log('Counting images')
    MediasDataService.getCount('mime_contains=image')
      .then((response) => {
        this.totalNumberOfImages = response.data
        this.totalNumberOfImagePages = Math.ceil(this.totalNumberOfImages / 15)

        console.log('Fething Images from Api.')
        if (this.imageSearchTerm.length > 0) {
          MediasDataService.findBySearchTerm(this.imageSearchTerm, 'updated_at:desc', { slicemode: 1, start: 0, limit: -1, page: (this.currentImagePage - 1), pagesize: 15, totalcount: this.totalNumberOfImages }, 'mime_contains=image')
            .then((response) => {
              this.imageFiles = response.data
            })
            .catch((err) => {
              console.error(err)
            })
        } else {
          MediasDataService.getAll('updated_at:desc', { slicemode: 1, start: 0, limit: -1, page: (this.currentImagePage - 1), pagesize: 15, totalcount: this.totalNumberOfImages }, 'mime_contains=image')
            .then((response) => {
              this.imageFiles = response.data
            })
            .catch((err) => {
              console.error(err)
            })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      const userString = localStorage.getItem('user')?.toString()
      const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

      if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.id < 100 && userDataObject.role !== undefined && userDataObject.role !== null && userDataObject.role.id === 5) {
        this.retrieveRegions()
        this.retrieveClubs()
        this.retrieveExternalServices()
        this.retrieveDate()
        // console.log('Clubs mounted value 1 = ' + BaseFunctions.defEnrcpyt(''))
      } else {
        CommonFunctions.redirectLogin()
      }
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
