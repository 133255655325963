import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07c8a721"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "google-maps"
}
const _hoisted_2 = { id: "map-container" }
const _hoisted_3 = {
  key: 1,
  class: "progressBarFlex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gmp_advanced_marker = _resolveComponent("gmp-advanced-marker")!
  const _component_gmp_map = _resolveComponent("gmp-map")!
  const _component_va_progress_circle = _resolveComponent("va-progress-circle")!

  return (_ctx.isMapScriptLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_gmp_map, {
            center: _ctx.center,
            zoom: _ctx.zoom,
            "map-id": "mapId",
            ref: "map",
            style: {"height":"400px"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locationInfo, (marker, index) => {
                return (_openBlock(), _createBlock(_component_gmp_advanced_marker, {
                  key: index,
                  position: marker.position,
                  title: marker.title,
                  onClick: ($event: any) => (_ctx.selectLocation(marker)),
                  style: {"cursor":"pointer"}
                }, null, 8, ["position", "title", "onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["center", "zoom"])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_va_progress_circle, {
          indeterminate: "",
          thickness: 0.4,
          large: ""
        }, null, 8, ["thickness"])
      ]))
}